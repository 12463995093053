import React, { useEffect } from 'react';
import $ from 'jquery';

const JQUERY = () => {
    useEffect(() => {

      const logo = document.getElementById('logoTop');

      // Cambia el color de la barra de navegación al hacer scroll
      $(window).on('scroll', function() {
        if ($(window).scrollTop() > 50) {
          $('.navbar').addClass('navClass'); // Cambia el color a naranja 
          $('.navbar-light .navbar-toggler-icon').removeClass('blanco');
          $('.navbar-toggler').removeClass('white');
        } else {
          $('.navbar').removeClass('navClass'); // Vuelve a transparente
          $('.navbar-light .navbar-toggler-icon').addClass('blanco');
          $('.navbar-toggler').addClass('white');
        }

      });

      /*$('.navbar-toggler').on("click",function() {
        $('.navbar-collapse').css('display', 'block');
      });*/
      $('.container-fluid button').on("click", function() {
        $('#navbarToggler').toggleClass('mostrar');
      });

      $('.navbar-brand').on("click", function() {
        $('.navbar-collapse').removeClass('mostrar');
      });
      $('section .top').on("click", function() {
        $('.navbar-collapse').removeClass('mostrar');
      });

      $('.nav_link_color').on("click", function() {
        $('.navbar-collapse').removeClass('mostrar');
      });

    }, []);
  
    return (
      <></>
    );
  };
  
  export default JQUERY;
