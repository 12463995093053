import React, { Component } from 'react';
import './App.css';
import Header from './componentes/Header';
import JQUERY from './componentes/Jquery';
import SECCION_UNO from './componentes/SeccionUno';
import SECCION_DOS from './componentes/SeccionDos';
import SECCION_TRES from './componentes/SeccionTres';
import CONTACTO from './componentes/Contacto';
import FOOTER from './componentes/Footer';


function App() {
  return (
    <section className="App">
      <JQUERY />
      <Header />
      <SECCION_UNO />
      <SECCION_DOS />
      <SECCION_TRES />
      <CONTACTO />
      <FOOTER />
    </section>
  );
}

export default App;
