import 'bootstrap/dist/css/bootstrap.min.css';


function SECCION_UNO() {
  return (

    <section className="seccion_uno" id='quienes'>
      <div className="container">
        <div>
          <div className="row">
            <div className="col-3">               
              <div className="conteVideo">
                <p>Conoce a nuestra CEO</p>
                <video autoPlay controls muted loop>
                  <source src="videos/video_ominim.mp4" type="video/mp4" />
                  Tu navegador no soporta el video.
                </video>
              </div>
              <div>
                  <img src="imagenes/marco_video-02.png" alt=""></img>
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-3"></div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>

      <div className="fondoBlanco" id='historia'>
        <div className="container">
          <div className="row">
            <div className="col-5">
                <h4>
                  nuestra
                </h4>
                <h2>
                  historia
                </h2>
                <p>
                  Luego de vivir en España 5 años trabajando para agencias de publicidad, <br></br>
                  como directora de arte, Amorina decide regresar a vivir a Argentina con un <br></br>
                  objetivo, imponer un modelo de negocio basado en la sorpresa. Habiendo <br></br>
                  nacido su primera hija en España quiere volver para que crezca junto a su flia. <br></br>
                  En el año 2007 abre Ominim en la ciudad de La Plata y a los 3 años nacería su <br></br>
                  primer franquicia. A partir de entonces no hemos parado de llevar sonrisas a <br></br>
                  distintos puntos de Argentina.
                </p>   
            </div>
            <div className="col-3"></div>
            <div className="col-3"></div>
          </div>      
        </div>
      </div>     
    </section>

  );
}

export default SECCION_UNO;