import 'bootstrap/dist/css/bootstrap.min.css';
// import Swiper core and required modules
import { /*Navigation, Pagination,*/ Scrollbar, A11y, Autoplay, Controller,Manipulation  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
/*import 'swiper/css/navigation';
import 'swiper/css/pagination';*/
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';


function SECCION_DOS() {
  
return (

<section className="seccion_dos" id='locales'  /*style={{backgroundImage: 'url(imagenes/fondo_servicios.png)',backgroundRepeat: 'no-repeat'}}*/ >

<div className="conte_swiper">
    <Swiper
        // install Swiper modules
        modules={[/*Navigation, Pagination,*/ Scrollbar, A11y, Autoplay, Controller,Manipulation ]}               
        init={true}
        loop={true}        
        breakpoints={{
          550: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          551: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        slidesPerView={2}
        spaceBetween={20} 
        autoplay={{ delay: 1600, disableOnInteraction: false }}
        /*navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: false }}*/
        //onSwiper={(swiper) => console.log(swiper)}
        //onSlideChange={() => console.log('slide change')}
      >
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-1.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-2.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-3.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-4.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-5.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-6.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-7.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-8.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-9.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-10.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-11.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
        <SwiperSlide><img src="imagenes/locales/franquicias-ominim-local-12.jpg" alt="franquicias-ominim-local"></img></SwiperSlide>
      </Swiper>
</div>
</section>

);
}

export default SECCION_DOS;