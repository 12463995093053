import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useRef  } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const CONTACTO = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    zona: '',
    inversion: '',
    horario: '',
    /*message: '',*/
  });
  const maniana = 'Mañana'; 
  const tarde = 'Tarde'; 
  const capitalSi = 'Si'; 
  const capitalNo = 'No'; 
    
  const [captcha, setCaptcha] = useState(null);
  const [errors, setErrors] = useState({});
  const [horario, setHorarios] = useState({tarde, maniana});
  const [capital, setCapitales] = useState({capitalSi, capitalNo});

   // Referencia para el reCAPTCHA
   const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target; 
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) {
      formErrors.name = 'Debe ingresar su Nombre';
    } else if (formData.name.length < 3){
      formErrors.name = 'Debe ingresar al menos 3 caracteres';
    } else if(formData.name.length > 30){
      formErrors.name = 'Máximo 30 caracteres';
    }

    if (!formData.email) {
      formErrors.email = 'Debe ingresar su Email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'La dirección de Email es invalida';
    }

    if (!formData.whatsapp) {
      formErrors.whatsapp = 'Debe ingresar un número';
    } else if (formData.whatsapp.length < 10) {//(wassapp.value.length < 10 || wassapp.value.length >= 11)
      formErrors.whatsapp = 'Debe ingresar 10 dígitos';
    } else if (formData.whatsapp.length > 10) {//(wassapp.value.length < 10 || wassapp.value.length >= 11)
      formErrors.whatsapp = 'Máximo 10 dígitos';
    }

    if (!formData.zona) {
      formErrors.zona = 'Debe ingresar una Zona de interés';
    } else if (formData.zona.length < 3){
      formErrors.zona = 'Debe ingresar al menos 3 caracteres';
    } else if (formData.zona.length > 40){
      formErrors.zona = 'Máximo 40 caracteres';
    }

    if (!captcha) formErrors.captcha = 'El captcha es obligatorio';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const enviaForm = document.getElementById('enviaForm');
      enviaForm.innerHTML = ' <i class="fas fa-spinner fa-pulse"></i> '; 

      const enviar = document.getElementById('enviar');
      enviar.innerHTML = 'Enviando..'; 

      try {
        const response = await fetch('https://www.ominimsorpreseria.com/php/send_mail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            ...formData,
            'g-recaptcha-response': captcha
          }).toString(),  
        });
  
        if (response.ok) {
          const enviaFormdOS = document.getElementById('enviaForm');
          enviaFormdOS.innerHTML = '  '; 
          enviar.innerHTML = 'Enviar'; 
          console.log('El formulario se envió con éxito:', formData);
          alert('¡Gracias! Tu mensaje ha sido enviado con éxito.');   

          // Reset form fields
          setFormData({
            name: '',
            email: '',
            whatsapp: '',
            zona: '',
            horario:'',
            /*message: '',*/
            enviaFormdOS: ' ',
          });
          setCaptcha(null);

          // Reset reCAPTCHA
          recaptchaRef.current.reset();
        } else {
          alert('Algo sucedió y no pudimos enviar tu mensaje.');
        }
      } catch (error) {
        alert('Error en la conexión. Intenta de nuevo más tarde.');

        // Reset form fields
        setFormData({
          name: '',
          email: '',
          whatsapp: '',
          zona: '',
          horario:'',
          /*message: '',*/
          enviaFormdOS: ' ',
        });
        setCaptcha(null);
      }
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  return (
    <section className="seccion_cuatro" id="contacto">

      <div className="container">
        <div className="col-12">
          <div className="contForm  wow fadeInLeft animated">
            <form onSubmit={handleSubmit}>
                <div style={{border:'transparent'}}><h6 className="text-left">(*)Campos obligatorios</h6></div>
                  <div>
                    <label htmlFor="name">  Nombre y apellido</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Escriba su nombre completo"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.name && <p className="error">{errors.name}</p>}
                 
                  <div>
                    <label htmlFor="whatsapp ">Celular</label>
                    <input
                      type="number"
                      id="whatsapp"
                      name="whatsapp"
                      placeholder="Ej.: 11 6666 2222"
                      pattern="\d{10}"
                      minLength="10"
                      maxLength="10" 
                      value={formData.whatsapp}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.whatsapp && <p className="error">{errors.whatsapp}</p>}

                  <div>
                    <label htmlFor="email">Mail</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="correo@mail.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.email && <p className="error">{errors.email}</p>}

                  <div>
                    <label htmlFor="zona">Zona de interés</label>
                    <input
                      type="text"
                      id="zona"
                      name="zona"
                      placeholder="Nombre de la Zona de interés"
                      value={formData.zona}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.zona && <p className="error">{errors.zona}</p>}

                  <div className='conteChecks inversion'>
                    <section><label for="inversion">Inversión requerida: <b style={{fontSize:'1.2rem'}}> desde U$S 25.000 </b></label></section >
                    <div>                      
                      <input type="radio" id="capitalSi" name="inversion" value={capital.capitalSi} onChange={handleChange}/>
                      <label for="capitalSi">Si</label>

                      <input type="radio" id="capitalNo" name="inversion" value={capital.capitalNo} onChange={handleChange}/>  
                      <label for="capitalNo">No</label>
                                          
                    </div>
                  </div>
                  {errors.inversion && <p className="error">{errors.inversion}</p>}


                  <div className='conteChecks'>
                    <label for="horario">Horario de contacto</label>
                    <div>                      
                      <input type="radio" id="maniana" name="horario" value={horario.maniana} onChange={handleChange}/>
                      <label for="maniana">mañana</label>

                      <input type="radio" id="tarde" name="horario" value={horario.tarde} onChange={handleChange}/>  
                      <label for="tarde">tarde</label>
                                          
                    </div>
                  </div>

                    {/* <div className="contTxtArea">
                        <label htmlFor="message">Message</label>
                        <textarea
                          id="message"
                          name="message"
                          placeholder="Escriba su mensaje"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        {errors.message && <p className="error">{errors.message}</p>}
                      </div>*/}

              <div className="recapcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeBDNQZAAAAAO0s5_wZcO8hmes1HNwt10a2l6tq"
                  onChange={handleCaptchaChange}
                />                
              </div>
              {errors.captcha && <p className="error">{errors.captcha}</p>}
              <div style={{border:'transparent', marginTop: '3rem'}}> 
                <button type="submit" id="enviar">Enviar</button>
              </div>
              
              <div id="enviaForm"style={{border:'transparent', color:'white', padding: '0'}}> </div>
            </form>

          </div>

        </div> 
      </div>

    </section>
  );
};

export default CONTACTO;


