import 'bootstrap/dist/css/bootstrap.min.css';
import { swiffyslider } from 'swiffy-slider'
import 'swiffy-slider/css';


function SECCION_TRES() {
    window.swiffyslider = swiffyslider;

    window.addEventListener("load", () => {
        window.swiffyslider.init();
    });

  return (

    <section className="seccion_tres" id="franquicias" style={{background:'url(imagenes/video.jpg)',backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
      <div className="container">
            <div>
              <div className="col-12 wow fadeInDown animated">
                <img src="imagenes/animate-02.png" alt=""></img>
              </div>
            </div>
      </div>
    </section>

  );
}

export default SECCION_TRES;